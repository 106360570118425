import React from 'react';
import {
  Link,
  List,
  GoodBad,
  GoodBadItem,
  PageHero,
  PageWithSubNav,
  Paragraph,
  Section,
  SectionSubhead,
} from 'docComponents';
import pageHeroData from '../../../../data/pages/words.yml';

const IndexPage = () => {
  return (
    <PageWithSubNav pageType="design" title="Action Words" subnav="words">
      <PageHero heroData={pageHeroData} tierTwo="Content Elements" tierThree="Action Words" />
      <Section>
        <Paragraph>
          <strong>
            The more familiar a user is with the words themselves, the more confident they become in using the product.
          </strong>
        </Paragraph>
        <Paragraph>
          Below is a closer look at what to say and when to say it, along with possible alternatives and the synonyms
          we’d like to avoid.
        </Paragraph>
      </Section>
      <Section>
        <Section title="Right and Wrong" />
        <Paragraph>
          To kick things off, we’ll start with two lists: words we should use and words we’d like to omit. Neither list
          is exhaustive, but both cover most of what we’ve seen in the product (however dated or new the feature may
          be).
        </Paragraph>
        <GoodBad gridLayout="2">
          <GoodBadItem type="bad">
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <List>
                <li>Modify</li>
                <li>Pay</li>
                <li>Show</li>
                <li>Stat</li>
                <li>Submit</li>
                <li>Update</li>
              </List>
            </div>
          </GoodBadItem>
          <GoodBadItem type="good">
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
              <List>
                <li>Add</li>
                <li>Cancel</li>
                <li>Create</li>
                <li>Delete</li>
                <li>Edit</li>
                <li>Manage</li>
              </List>
              <List>
                <li>Remove</li>
                <li>Restore</li>
                <li>Save</li>
                <li>Search</li>
                <li>Select</li>
                <li>View</li>
              </List>
            </div>
          </GoodBadItem>
        </GoodBad>
        <Paragraph>
          What makes each good or bad? You can’t argue redundancy, because there are a handful of synonyms listed as{' '}
          <em>good</em>. Nor can you argue specificity, because a lot of the bad items are more specific than anything
          else.
        </Paragraph>
      </Section>
      <Section title="What to Use When" />
      <Paragraph>
        It really comes down to context. Where are they in the product? What's the object of this action? How{' '}
        <em>final</em> is the thing they're about to do? With every answer, a clear correct action will come to light.
      </Paragraph>
      <Section>
        <SectionSubhead>Add vs Create</SectionSubhead>
        <Paragraph />
        <Paragraph>
          <strong>Add</strong> comes into play when putting a new element into a larger system that already exists.
          Think of athletes on a roster, events on a schedule, data in the breakdown columns.{' '}
          <strong>Add is for the individual part.</strong>
        </Paragraph>
        <Paragraph>
          <strong>Create</strong> applies when building a larger system to which other elements will be added, like
          installs, seasons, groups and reports. They all house additional pieces and information.
        </Paragraph>
      </Section>
      <Section>
        <SectionSubhead>Delete vs Remove</SectionSubhead>
        <Paragraph />
        <Paragraph>
          <strong>Delete</strong> means the element will no longer exist. It doesn't live anywhere else within Hudl.
          Rather, it's tied directly to the system a user is currently viewing, and choosing to delete will destroy it
          entirely.
        </Paragraph>
        <Paragraph>
          <strong>Remove</strong> works when separating an element from the system in which it's currently being viewed.
          Removing an athlete from the roster doesn't "destroy" the athlete entirely. They're simply no longer
          associated with that team.
        </Paragraph>
      </Section>
      <Section>
        <SectionSubhead>Edit vs Manage</SectionSubhead>
        <Paragraph />
        <Paragraph>
          <strong>Edit</strong> to directly change the physical attributes of an element so it ultimately looks
          different in the end.
        </Paragraph>
        <Paragraph>
          <strong>Manage</strong> changes aren't physical so much as contextual, like where an element lives or who’s
          allowed to see it.
        </Paragraph>
        <Paragraph>
          <em>
            Note: We never want to swap in ‘modify’. It could mean one or the other which causes a ton of confusion
            without contributing anything to the larger vocab.
          </em>
        </Paragraph>
      </Section>
      <Section>
        <SectionSubhead>Send vs Share</SectionSubhead>
        <Paragraph />
        <Paragraph>
          <strong>Send*</strong> applies when the content has a final destination at which point its purpose is served.
          No other action can be taken on that content. It's a quick one-and-done interaction for all parties.
        </Paragraph>
        <Paragraph>
          <strong>Shared</strong> content remains open and available for further interaction by both the sender and
          their recipient(s). This is usually the case with highlights, playlists and other video.
        </Paragraph>
        <Paragraph>
          *Send should always be used in place of <em>pay</em> and <em>submit</em>. Payments are one-and-done, no
          additional action needed, while <em>submit</em> means the same thing, but in a less conversational tone.
        </Paragraph>
      </Section>
      <Section>
        <SectionSubhead>Everything Else</SectionSubhead>
        <Paragraph />
        <Paragraph>The other members of the Good List are all pretty clear and have specific applications: </Paragraph>
        <List>
          <li>
            <strong>Search</strong> for the search bar
          </li>
          <li>
            <strong>Select</strong> for selects
          </li>
          <li>
            <strong>Restore</strong> as a counter to remove
          </li>
        </List>
        <br />
        <Paragraph>
          <strong>Cancel</strong> appears <em>everywhere</em>, but—as a true{' '}
          <Link href="/components/buttons/button/design#Type">cancel button</Link>
          —always does the same thing.{' '}
        </Paragraph>
        <Paragraph>
          <strong>Save</strong> and <strong>view</strong>, however, do have “bad” synonyms.
        </Paragraph>
        <Paragraph>
          We choose <strong>save</strong> over <em>update</em> purely because save is universally understood and
          accomplishes everything an update would, and it’s really not worth the hassle of trying to dictate/remember
          what goes where.
        </Paragraph>
        <Paragraph>
          <strong>View</strong> wins out over <em>show</em> because viewing is an active state throughout the product.{' '}
          <em>Showing</em> is up to Hudl, while choosing to <strong>view</strong> is in the user's hands. Empower them
          to take control!
        </Paragraph>
        <Paragraph>
          Now let's discuss the final word from the total list. As you can see, <em>stat</em> is bad. It’s so bad, that
          if you ever use it as a verb—either in the product or via content marketing—the ghosts of Hudl writers past
          will haunt you for the rest of time.
        </Paragraph>
        <Paragraph>
          And that's all we have to say about{' '}
          <Link href="https://media.giphy.com/media/eWJKz0mvZwbL2/giphy.gif">that</Link>.
        </Paragraph>
      </Section>
    </PageWithSubNav>
  );
};

export default IndexPage;
